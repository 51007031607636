import { EditOutlined } from '@ant-design/icons';
import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import { Button, Form, FormProps, Input, Modal } from 'antd';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

interface FieldType {
  additionalInfo: string;
}

interface ModalEditTextAreaProps {
  initialValue: string | undefined;
  handleUpdateData: (values: string) => void;
  loading: boolean;
  wording: {
    title: string;
    label: string;
    placeholder: string;
  };
}

function ModalEditTextArea({ initialValue, handleUpdateData, loading, wording }: Readonly<ModalEditTextAreaProps>): ReactNode {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const additionalInfoValue = Form.useWatch('additionalInfo', form);

  const handleSubmit: FormProps<FieldType>['onFinish'] = (values) => {
    setIsModalOpen(false);
    handleUpdateData(values.additionalInfo);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setIsModalOpen(true);
        }}
        icon={<EditOutlined />}
      />
      <Modal
        title={wording.title}
        open={isModalOpen}
        onOk={() =>{
          form.submit();
        }}
        onCancel={handleCancel}
        confirmLoading={loading}
        okButtonProps={{ disabled: additionalInfoValue === initialValue }}
        okText={t('UPDATE_BUTTON')}
        cancelText={t('CANCEL_UPDATE')}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          style={{ marginBottom: 40 }}
          initialValues={{
            additionalInfo: initialValue,
          }}
        >
          <Form.Item<FieldType>
            name="additionalInfo"
            label={wording.label}
            rules={[
              {
                pattern: NO_EMOJI_REGEX,
                message: t('INVALID_VALUE'),
              },
            ]}
          >
            <TextArea showCount maxLength={255} placeholder={wording.placeholder} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ModalEditTextArea;
