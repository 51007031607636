import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import { useKeydownListener, useTrimmedFields } from '@shopopop/react-hooks';
import { Col, Flex, Form, FormInstance, Input, Modal, Row, Typography } from 'antd';
import { CountryCode } from 'libphonenumber-js';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NO_SPECIAL_CHARACTERS_REGEX } from '../../../../../utils/src/regex/regex.ts';
import SearchSummary from '../../Cards/SearchSummary.tsx';
import InputPhoneNumber from '../../Input/InputPhoneNumber/InputPhoneNumber.tsx';

const { Text } = Typography;

interface ModifyUserSearchProps {
  country: string;
  form: FormInstance;
  initialSearchName?: string;
  initialSearchEmail?: string;
  initialSearchPhone?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getUsers: Function; // Add getUsers prop
}

function ModifyUserSearch({ country, form, initialSearchName, initialSearchEmail, initialSearchPhone, getUsers }: Readonly<ModifyUserSearchProps>): ReactNode {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formInError, setFormInError] = useState(false);

  // Trim the email input value
  useTrimmedFields({
    watchedInput: {
      value: Form.useWatch('email', form),
      name: 'email',
    },
    setFormValue: form.setFieldsValue,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const areFieldsInvalid = (fields: { [key: string]: any }): boolean => {
    return Object.values(fields).every((value) => !value);
  };

  const handleOk = async () => {
    const fields = await form.validateFields();

    if (!areFieldsInvalid(fields)) {
      setFormInError(false);
      setLoading(true);
      const values = await form.validateFields();
      await getUsers({ ...values, page: 1, limit: 10 });
      setIsModalOpen(false);
      setLoading(false);
    } else {
      setFormInError(true);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  useKeydownListener({ canTriggerKeydown: isModalOpen, onKeydown: handleOk });

  const concatenatedUserInfo = [initialSearchName, initialSearchEmail, initialSearchPhone].filter(Boolean).join(', ');

  return (
    <>
      <SearchSummary title={t('SEARCH_EXISTING_RCP_TITLE')} subtitle={concatenatedUserInfo} handleAction={showModal} />
      <Modal
        title={t('MODIFY_SEARCH_EXISTING_RCP_TITLE')}
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ loading }}
        okText={t('RCP_SEARCH_BUTTON')}
        onCancel={handleCancel}
        style={{
          minWidth: '50%',
        }}
      >
        <Form
          layout={'vertical'}
          form={form}
          onValuesChange={() => setFormInError(false)}
          initialValues={{
            name: initialSearchName,
            email: initialSearchEmail,
            phone: initialSearchPhone,
          }}
        >
          <Flex gap={12} vertical>
            <Text italic type={formInError ? 'danger' : 'secondary'} data-testid="textExplain-FormUserSearch">
              {t('SEARCH_EXISTING_RCP_DESC')}
            </Text>
            <Row gutter={20}>
              <Col xs={24} md={24}>
                <Form.Item
                  name="name"
                  label={t('RCP_SEARCH_FIRSTNAME_LASTNAME')}
                  rules={[
                    {
                      pattern: /^[^\d]*$/g,
                      message: t('NUMBER_NOT_ALLOWED'),
                    },
                    {
                      pattern: /^(?=.{1,100}$).*/g,
                      message: t('FULL_NAME_LIMITE_LENGTH'),
                    },
                    {
                      pattern: NO_SPECIAL_CHARACTERS_REGEX,
                      message: t('INVALID_VALUE'),
                    },
                    {
                      pattern: NO_EMOJI_REGEX,
                      message: t('INVALID_VALUE'),
                    },
                  ]}
                >
                  <Input data-testid="inputName-FormUserSearch" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  label={t('RCP_SEARCH_EMAIL')}
                  rules={[
                    {
                      type: 'email',
                      message: t('WRONG_FORMAT_EMAIL'),
                    },
                    {
                      pattern: NO_EMOJI_REGEX,
                      message: t('INVALID_VALUE'),
                    },
                  ]}
                >
                  <Input data-testid="inputEmail-FormUserSearch" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                {/*
                  // @ts-expect-error - to resolve */}
                <InputPhoneNumber country={country as CountryCode} form={form} initialPhoneNumber={initialSearchPhone} />
              </Col>
            </Row>
          </Flex>
        </Form>
      </Modal>
    </>
  );
}

export default ModifyUserSearch;
