import { DeliveryListTabNames, DeliveryStatusEnum, useDeliverySlotDisplayRules } from '@shopopop/react-hooks';
import { Layout, Typography } from 'antd';
import dayjs from 'dayjs';
import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface DeliveryTimeslotTupleProps extends PropsWithChildren {
  pickupTime?:
    | {
        start: string; // ISO8601 string
        end: string; // ISO8601 string
      }
    | undefined;
  deliveryTime: {
    start: string; // ISO8601 string
    end: string; // ISO8601 string
  };
  status: DeliveryStatusEnum;
  deliveriesListTab: DeliveryListTabNames;
}

function DeliveryTimeslotTuple({ pickupTime, deliveryTime, status, deliveriesListTab, children }: DeliveryTimeslotTupleProps): ReactNode {
  const { t } = useTranslation();
  const { showPickupTime, isPickupTimeBold, isDeliveryTimeBold } = useDeliverySlotDisplayRules(status, deliveriesListTab);

  return (
    <Layout style={{ backgroundColor: 'transparent' }}>
      {children}
      {pickupTime && showPickupTime && (
        <Text strong={isPickupTimeBold}>
          {t('DELIVERY_LIST_PU_SLOT')} {dayjs(pickupTime.start).format('LT')} - {dayjs(pickupTime.end).format('LT')}
        </Text>
      )}
      <Text strong={isDeliveryTimeBold}>
        {showPickupTime && t('DELIVERY_LIST_TIMESLOT')} {dayjs(deliveryTime.start).format('LT')} - {dayjs(deliveryTime.end).format('LT')}
      </Text>
    </Layout>
  );
}

export default DeliveryTimeslotTuple;
