import { EditOutlined } from '@ant-design/icons';
import { Button, Row, Space, theme, Typography } from 'antd';
import { ReactNode } from 'react';

const { Text } = Typography;

interface SearchSummaryProps {
  title: string;
  subtitle: string;
  handleAction: () => void;
}

export default function SearchSummary({ title, subtitle, handleAction }: SearchSummaryProps): ReactNode {
  const {
    token: { borderRadius, colorBgContainer, colorBorder },
  } = theme.useToken();

  const containerStyle = {
    padding: '8px 12px',
    backgroundColor: colorBgContainer,
    borderRadius,
    border: `1px solid ${colorBorder}`,
  };

  return (
    <Row justify="space-between" wrap={false} align="middle" style={containerStyle}>
      <Space style={{ flexWrap: 'wrap', fontWeight: 600 }}>
        <Text>{title} : </Text>
        <Text type="secondary" style={{ textWrap: 'wrap' }}>
          {subtitle}
        </Text>
      </Space>
      <Button type="text" shape="circle" icon={<EditOutlined />} onClick={handleAction} />
    </Row>
  );
}
