import { useTranslation } from 'react-i18next';
import mapI18N from '../assets/mapI18N';

interface Locale {
  file: unknown;
  keys: string[];
  name: string;
}

type LanguageChangeHandler = (value: string) => Promise<void>;

/**
 * useChangeLanguage
 * @return {LanguageChangeHandler}
 */
export default function useChangeLanguage(): LanguageChangeHandler {
  const { i18n } = useTranslation();

  return async(value: string) => {
    const lang: string = mapI18N.find((l: Locale) => l.name === value)!.keys[0] as string;

    await i18n.changeLanguage(lang, (err: Error | null) => {
      if (err) {
        throw err;
      }
    });
  };
}
