import { Card, Col, Divider, Row, Skeleton, Typography } from 'antd';
import { Fragment } from 'react';
import { DetailSection, StructuredInfoCard, TitleCard } from '../..';

const { Text } = Typography;

interface DetailCardProps {
  title: string;
  secondaryTitle: string;
  loading: boolean;
  sections: DetailSection[];
}
type DetailContentProps = Pick<DetailCardProps, 'loading' | 'sections'>;

export function DetailWithDividerContent({ loading, sections }: DetailContentProps) {
  return loading ? (
    <Skeleton active />
  ) : (
    sections.map(({ items, layout, label: sectionLabel }, index) => (
      <Fragment key={`${sectionLabel}_${index}`}>
        <Row gutter={[0, 20]}>
          {sectionLabel && (
            <Col span={24}>
              <Text strong>{sectionLabel} : </Text>
            </Col>
          )}
          {items.map(({ label, value, fullRow = false }, indexItem) => (
            <Col xs={24} sm={fullRow || layout === 'all-half' ? 24 : 12} md={fullRow ? 24 : 12} key={`${value}${indexItem}`}>
              <StructuredInfoCard
                label={label}
                value={value || '-'}
                direction="vertical" />
            </Col>
          ))}
        </Row>
        {index < sections.length - 1 && <Divider />}
      </Fragment>
    ))
  );
}

export function DetailWithDividerCard({ loading, secondaryTitle, sections, title }: Readonly<DetailCardProps>) {
  return (
    <Card title={<TitleCard principalWording={title} secondaryWording={secondaryTitle} />}>
      <DetailWithDividerContent loading={loading} sections={sections} />
    </Card>
  );
}
