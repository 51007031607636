import React from 'react';
import {
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import addressFormatter from '../../utils/addressFormatter';
import { transformElevatorForHuman } from '@shopopop/backoffice-frontend-utils';

/**
 * PropTypes for the AddressCard component.
 * @property {object} address - The address object.
 * @property {boolean} [loading] - Indicates if the card is in a loading state.
 * @return {ReactNode}
 */
function AddressCard({ address, loading }) {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <Card title={t('ADDRESS')}>
      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[0, 16]}>
          <Col xs={24}>
            <Space direction="vertical">
              <Text type="secondary">{t('ADDRESS')}</Text>
              <Text>
                {addressFormatter(address)}
              </Text>
              <Text>
                {t('FLOOR')} : {address.floor}, {t('ELEVATOR')} : {t(transformElevatorForHuman(address.elevator))}
              </Text>
              <Text type="secondary">{t('ADDRESS_ADDITIONAL_INFO')}</Text>
              <Text>{address.additionalInfo ?? '-'}</Text>
            </Space>
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default AddressCard;
