import { ArrowRightOutlined, CalendarOutlined, ClockCircleTwoTone } from '@ant-design/icons';
import styled from '@emotion/styled';
import { getFormattedDateOrOtherDate } from '@shopopop/backoffice-frontend-utils';
import { useDeliveryDateTime, useScreenSize } from '@shopopop/react-hooks';
import { Col, DatePicker, Flex, Form, FormInstance, Input, Radio, Row, Space, TimePicker, TimePickerProps, Typography, theme } from 'antd';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { boDateFromString, getDateReferences } from '../../../../utils/src/date/date';
import DateSelector from './DateSelector';

const { Text } = Typography;

const getDisabledMinutes = (selectedHour: number) => {
  if (selectedHour !== 20) return [];

  const result: number[] = [];
  for (let i = 1; i <= 59; i++) {
    result.push(i);
  }
  return result;
};

function DeliveryDateTimeBody({ form, forceRefresh }: { form: FormInstance, forceRefresh?: boolean }): ReactNode {
  const { t, i18n } = useTranslation();
  const {
    token: { screenLG, colorPrimary },
  } = theme.useToken();
  const { width, tabletMode } = useScreenSize();
  const { startDate, endDate, validateTimeRange, handleStartTimeChange, handleEndTimeChange, handleDateChange } = useDeliveryDateTime(form, forceRefresh);
  const [selectedDate, setSelectedDate] = useState(dayjs(form.getFieldValue('deliveryDate')).startOf('day').toISOString());

  const handleDatePickerChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const value = date.startOf('day').toISOString();
      setSelectedDate(value);
      form.setFieldsValue({ deliveryDate: value });
      handleDateChange(value);

      // Mettre à jour la valeur du groupe de boutons radio
      const radioValue = dateOptions.find((option) => option.value === value)?.value ?? value;
      form.setFieldsValue({ deliveryDate: radioValue });
    }
  };
  useEffect(() => {
    handleDatePickerChange(dayjs(selectedDate));
  }, []);

  const dateOptions = Object.entries(getDateReferences(['TODAY', 'TOMORROW', 'AFTER_TOMORROW'])).map(([key, value]) => {
    return {
      value: value,
      labelKey: key,
      date: boDateFromString(value),
    };
  });

  const renderDatePicker = () => (
    <DatePicker
      value={dayjs(form.getFieldValue('deliveryDate'))}
      onChange={(value) => handleDatePickerChange(value)}
      format={'dddd D MMMM'}
      disabledDate={(current) => current?.isBefore(dayjs().startOf('day'))}
      allowClear={false}
      style={tabletMode ? { width: '100%' } : {}}
      inputReadOnly
      suffixIcon={<CalendarOutlined style={{ color: colorPrimary }} />}
    />
  );

  const getDateText = (date: dayjs.Dayjs, width: number, screenMD: number): string => {
    const afterTomorrow = dayjs().add(2, 'day').endOf('day');
    if (date.isAfter(afterTomorrow)) {
      return date.format(width > screenMD ? 'dddd D MMMM' : 'DD/MM/YY');
    } else {
      return 'OTHER_DATE';
    }
  };

  const extraOption = !tabletMode && (
    <StyledRadioButton value={getFormattedDateOrOtherDate(form.getFieldValue('deliveryDate'))} colorPrimary={colorPrimary}>
      <Flex vertical align={'center'}>
        <Text className="hide-mobile">
          <CalendarOutlined />
        </Text>
        <Text>{t(getDateText(startDate, width, screenLG))}</Text>
      </Flex>
      <StyledDatePickerRadioButton>{renderDatePicker()}</StyledDatePickerRadioButton>
    </StyledRadioButton>
  );

  const timePickerCommonProps: TimePickerProps = {
    format: 'HH:mm',
    minuteStep: 15,
    hideDisabledOptions: true,
    needConfirm: false,
    use12Hours: false,
    allowClear: false,
    style: {
      width: '100%',
      minWidth: 205,
    },
    suffixIcon: <ClockCircleTwoTone twoToneColor={colorPrimary} />,
    inputReadOnly: true,
  };

  return (
    <>
      <Form.Item name="startDate" style={{ display: 'none', visibility: 'hidden' }}>
        <Input />
      </Form.Item>
      <Form.Item name="endDate" style={{ display: 'none', visibility: 'hidden' }}>
        <Input />
      </Form.Item>

      <Flex vertical gap={24}>
        <Space direction={'vertical'}>
          <Form.Item label={t('DELIVERY_DATE')} name="deliveryDate" rules={[{ required: true, message: t('DELIVERY_DATE_REQUIRED_ERROR') }]} style={{ marginBottom: 0 }}>
            <DateSelector dateOptions={dateOptions} extraOption={extraOption} handleDateChange={handleDateChange} initialValue={selectedDate} />
          </Form.Item>
          {tabletMode && renderDatePicker()}
        </Space>
        <Form.Item
          label={t('DELIVERY_TIME_SLOT')}
          name="timeRange"
          rules={[{ required: true, validator: validateTimeRange }]}
          validateTrigger={['onBlur', 'onChange']}
          style={{ width: width > screenLG ? 'auto' : '100%' }}
        >
          <Row gutter={[0, 20]} align="middle" style={{ maxWidth: width > screenLG ? 720 : 'unset' }}>
            <Col xs={24} lg={11}>
              <TimePicker
                {...timePickerCommonProps}
                value={startDate}
                onChange={handleStartTimeChange}
                format={'LT'}
                // At this time, it only works for England. If other countries need this format, it needs to be made configurable.
                use12Hours={i18n.language === 'en-GB'}
                disabledTime={() => ({
                  disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
                  disabledMinutes: (hour) => getDisabledMinutes(hour),
                })}
              />
            </Col>
            <Col xs={0} lg={2} style={{ textAlign: 'center' }}>
              <ArrowRightOutlined style={{ color: colorPrimary }} />
            </Col>
            <Col xs={24} lg={11}>
              <TimePicker
                {...timePickerCommonProps}
                value={endDate}
                onChange={handleEndTimeChange}
                format={'LT'}
                // At this time, it only works for England. If other countries need this format, it needs to be made configurable.
                use12Hours={i18n.language === 'en-GB'}
                disabledTime={() => ({
                  disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23],
                  disabledMinutes: (hour) => getDisabledMinutes(hour),
                })}
              />
            </Col>
          </Row>
        </Form.Item>
      </Flex>
    </>
  );
}

const StyledRadioButton = styled(Radio.Button) <{ colorPrimary: string }>`
    height: auto;
    padding: 5px 16px;
    text-align: center;
    outline: 0 !important;

    @media (max-width: 992px) {
        width: calc(100% / 4);

        .ant-typography {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .hide-mobile {
            display: none;
        }
    }

    @media (max-width: 576px) {
        width: calc(100% / 3);
        .ant-picker {
            height: auto !important;
        }
    }

    &.ant-radio-button-wrapper-checked .ant-typography {
      color: ${({ colorPrimary }) => colorPrimary};
    }

    .anticon {
        color: ${({ colorPrimary }) => colorPrimary};
    }
`;

const StyledDatePickerRadioButton = styled.div`
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important;

    .anticon {
        display: none;
    }

    .ant-picker {
        width: 100%;
        height: 56px;
        border-radius: 0;
        border: 0;
        padding: 0;

        .ant-picker-input {
            height: 100%;
            border-radius: 0;

            input {
                height: 100%;
                border-radius: 0;
                cursor: pointer;
            }

            .ant-picker-suffix {
                display: none;
            }
        }
    }
`;

export default DeliveryDateTimeBody;
