import { WarningFilled } from '@ant-design/icons';
import { DeliveryStatusEnum } from '@shopopop/react-hooks';
import { Flex, Tooltip, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { generateMessages } from '../../../../../utils/src/utils/generateMessageDeliveryStatus';
import DeliveryStatusTag from '../../Tags/DeliveryStatusTag';

interface StatusTuplesProps {
  status: DeliveryStatusEnum;
  shiftedDate: boolean;
  shiftedTime: boolean;
  incident: boolean | null;
  isArchived: boolean;
}

function StatusTuples({ status, shiftedDate, shiftedTime, incident, isArchived }: Readonly<StatusTuplesProps>) {
  const { t } = useTranslation();
  const {
    token: { colorError },
  } = theme.useToken();
  const { alertMessage } = generateMessages(incident, shiftedDate, shiftedTime);

  return (
    <Flex gap={5}>
      <DeliveryStatusTag status={status} isArchived={isArchived} />
      {alertMessage && (
        <Tooltip title={t(alertMessage)} placement="bottom">
          <WarningFilled style={{ color: colorError }} />
        </Tooltip>
      )}
    </Flex>
  );
}

export default StatusTuples;
