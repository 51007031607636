import { Alert, Card, FormInstance } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryTimeBody from '../DateSelector/DeliveryTimeForm';

function DeliveryDateTime({ form }: Readonly<{ form: FormInstance }>): ReactNode {
  const { t } = useTranslation();

  return (
    <Card title={t('DATE_TIMESLOT_TITLE')}>
      <DeliveryTimeBody form={form} />
      <Alert message={t('DELIVERY_TIMESLOT_INSTRUCTION')} type="info" showIcon />
    </Card>
  );
}

export default DeliveryDateTime;
