import { Button, Card, Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../hooks/src/hooks/useDebounce';
import { AddressOption } from '../interfaces/RecipientAddressInformationProps';
import RecipientAddressFormItems from './Form/addresses/RecipientAddressFormItems.tsx';
import { AutocompleteStatusProps } from '../interfaces/AutocompleteStatusProps.ts';

interface RecipientDeliveryAddressProps {
  form: FormInstance;
  onAddressSearch: (query: string, language?: string) => Promise<AddressOption[]>;
  onAddressSelect?: ({ address, placeId }: AddressOption) => void;
  onAddressClear?: () => void;
  submitButton: {
    disabled: boolean;
    visible: boolean;
  };
  showOriginalAddress: string | null;
  id?: number | null;
  originalAddress: string | null;
}

function RecipientDeliveryAddress(props: Readonly<RecipientDeliveryAddressProps>): ReactNode {
  const { form, originalAddress, onAddressSearch, onAddressSelect, onAddressClear, submitButton = { disabled: false, visible: false }, showOriginalAddress = null } = props;

  const { t, i18n } = useTranslation();

  const [options, setOptions] = useState<AddressOption[]>([]);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(!!originalAddress);
  const [searchTerm, setSearchTerm] = useState('');
  const [previousSelectedAddress, setPreviousSelectedAddress] = useState(originalAddress);
  const [validateStatus, setValidateStatus] = useState<AutocompleteStatusProps>('');

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    // When an original address is set, the field is validated
    setValidateStatus(originalAddress ? 'success' : '');
  }, [form, originalAddress]);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 && debouncedSearchTerm.length < 100) {
      onAddressSearch(debouncedSearchTerm, i18n?.language).then((suggestions: AddressOption[]) => {
        setOptions(suggestions.slice(0, 5));
      });
    } else if (debouncedSearchTerm.length === 0) {
      setOptions([]);
    }
    if (originalAddress) setShowAdditionalInfo(true);
  }, [debouncedSearchTerm, onAddressSearch, originalAddress]);

  const handleSelect = (value: string) => {
    const { address, placeId } = JSON.parse(value);
    form.setFieldsValue({ address });
    setSearchTerm('');
    setOptions([]);
    setShowAdditionalInfo(true);
    if (previousSelectedAddress !== value) {
      setPreviousSelectedAddress(value);
      onAddressSelect?.({ address, placeId });
      setValidateStatus('success');
      form.setFieldsValue({
        elevator: 'dontknow',
        floor: null,
        comment: '',
      });
    }
  };

  return (
    <Card title={t('RCP_ADDRESS_TITLE')}>
      <Row gutter={16}>
        <Col xs={24}>
          <RecipientAddressFormItems
            options={options}
            searchTerm={searchTerm}
            validateStatus={validateStatus}
            setValidateStatus={setValidateStatus}
            onSearch={(value) => {
              setSearchTerm(value);
            }}
            onSelect={handleSelect}
            onClear={() => onAddressClear?.()}
            showOriginalAddress={showOriginalAddress}
            showAdditionalInfo={showAdditionalInfo}
          />
        </Col>
      </Row>
      {submitButton.visible && (
        <Button htmlType="submit" type="primary" disabled={submitButton.disabled}>
          {t('SAVE')}
        </Button>
      )}
    </Card>
  );
}

export default RecipientDeliveryAddress;
