import { Button, Form, Input, Skeleton, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { TFunction } from 'i18next';

const { TextArea } = Input;
const { Text } = Typography;
const { Item } = Form;

export const renderTitle = (isLoading: boolean, alreadyReported: boolean, t: TFunction) =>
  isLoading ? <Skeleton active paragraph={false} /> : <Text>{alreadyReported ? (t('CTP_REPORTING_ALREADY_DONE') as string) : (t('CTP_REPORTING_TITLE') as string)}</Text>;

export const renderContent = (isLoading: boolean, alreadyReported: boolean, t: TFunction, form: FormInstance) => {
  if (isLoading) return <Skeleton active paragraph={{ rows: 3 }} />;
  if (alreadyReported) return <Text>{t('CTP_REPORT_PROCESS_MSG') as string}</Text>;

  return (
    <>
      <Text type="secondary" italic>
        {t('CTP_REPORT_PROCESS_MSG') as string}
      </Text>
      <Form form={form} layout="vertical" style={{ marginTop: 20 }}>
        <Item name="description" label={t('CTP_REPORTING_DESC') as string} rules={[{ required: true, message: t('CTP_REPORTING_DESC_REQUIRED') as string }]}>
          <TextArea rows={4} maxLength={255} showCount style={{ marginBottom: 20 }} />
        </Item>
      </Form>
    </>
  );
};

export const renderFooter = (alreadyReported: boolean, handleCloseModal: () => void, handleSubmit: () => void, t: TFunction) =>
  alreadyReported ? (
    <Button key="ok" type="primary" onClick={handleCloseModal}>
      {t('OK') as string}
    </Button>
  ) : (
    <>
      <Button key="cancel" onClick={handleCloseModal}>
        {t('CTP_REPORTING_CANCELLATION_BUTTON') as string}
      </Button>
      <Button key="submit" type="primary" onClick={handleSubmit}>
        {t('CTP_REPORTING_VALIDATION_BUTTON') as string}
      </Button>
    </>
  );
