import { ArrowRightOutlined, WarningFilled } from '@ant-design/icons';
import styled from '@emotion/styled';
import { formatDateFullDate } from '@shopopop/backoffice-frontend-utils';
import { DeliveryListTabNames, DeliveryStatusEnum } from '@shopopop/react-hooks';
import { Button, Card, Space, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { generateMessages } from '../../../../utils/src/utils/generateMessageDeliveryStatus.ts';
import DeliveryPickupTuple from '../Tables/Tuples/DeliveryPickupTuple.tsx';
import DeliveryTimeslotTuple from '../Tables/Tuples/DeliveryTimeslotTuple.tsx';
import DeliveryStatusTag from '../Tags/DeliveryStatusTag.tsx';

const { Text } = Typography;

interface DeliveryCardListItemProps {
  deliveryId: number;
  deliveryTime: {
    start: string; // ISO8601 string
    end: string; // ISO8601 string
  };
  hasIncident: boolean;
  isArchived: boolean;
  pickupPoint?: {
    name: string;
    details: string;
  };
  pickupTime?: {
    start: string; // ISO8601 string
    end: string; // ISO8601 string
  };
  status: DeliveryStatusEnum;
  tabName: DeliveryListTabNames;
  title: string;
}

function DeliveryCardListItem({ deliveryId, deliveryTime, hasIncident, isArchived, pickupPoint, pickupTime, status, tabName, title }: DeliveryCardListItemProps) {
  const { t } = useTranslation();
  const {
    token: { colorFillQuaternary },
  } = theme.useToken();
  const navigate = useNavigate();
  const { alertMessage } = generateMessages(hasIncident);

  const StyledCard = styled(Card)`
      .ant-card-head {
          background-color: ${colorFillQuaternary};
      }
  `;

  return (
    <StyledCard title={title} size={'small'} extra={<Button shape="circle" icon={<ArrowRightOutlined />} onClick={() => navigate(`/delivery/${deliveryId}`)} />}>
      <Space direction={'vertical'} size={10}>
        {pickupPoint && <DeliveryPickupTuple name={pickupPoint.name} details={pickupPoint.details} />}
        <DeliveryTimeslotTuple pickupTime={pickupTime} deliveryTime={deliveryTime} status={status} deliveriesListTab={tabName}>
          {/* we only have pickup point in list display */}
          {pickupPoint ? null : <Text strong>{formatDateFullDate(deliveryTime.start)}</Text>}
        </DeliveryTimeslotTuple>
        <Space align="start">
          <DeliveryStatusTag status={status} isArchived={isArchived} />
          {alertMessage && (
            <Text type="danger">
              <WarningFilled style={{ marginRight: 5 }} />
              {t(alertMessage)}
            </Text>
          )}
        </Space>
      </Space>
    </StyledCard>
  );
}

export default DeliveryCardListItem;
