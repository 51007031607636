/** @jsxImportSource @emotion/react */
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { formatElevatorFormDataToApi, generateAddressObject } from '@shopopop/backoffice-frontend-utils';
import { RecipientDeliveryAddress } from '@shopopop/backoffice-ui';
import { useTransformElevatorData } from '@shopopop/react-hooks';
import { Button, Col, Form, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import useAddressSearch from '../../../../hooks/useAddressSearch';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { updateUserAddress } from '../../../../services/apiEndPoints/addresses/userAddress/updateUserAddress';
import { writeAddress } from '../../../../services/apiEndPoints/addresses/writeAddress';
import addressFormatter from '../../../../utils/addressFormatter';
import UserSearch from '../../../User/UserSearch/UserSearch';
import { StepContext } from '../CreateDeliveryForm';
import ButtonCreateNewUser from './ButtonCreateNewUser';
import PersonalInformationsFieldset from './PersonalInformationsFieldset';

/**
 * CreateDeliveryForm
 * @return {Form}
 */
function Step1({ store, dispatch }) {
  const { t } = useTranslation();
  useDocumentTitle(t('URL_DELIVERY_CREATION_RECIPIENT'));
  const [form] = Form.useForm();
  const { showCustomerForm, setShowCustomerForm, setCurrentStep, setStepsValidated } = useContext(StepContext);
  const notification = useContext(NotificationCenterContext);
  const [updateAddressLoading, setUpdateAddressLoading] = useState(false);

  // State adresses pour le composant AddressesListCard
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  // Transform elevator data for radio
  const { transformedElevatorData } = useTransformElevatorData();

  const distanceFetch = useFetch(process.env.REACT_APP_API_BO, {
    cacheLife: parseInt(process.env.REACT_APP_LOCAL_CACHING_DATA, 10),
  });
  const userFetch = useFetch((process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  }));

  useEffect(() => {
    setShowCustomerForm(store.delivery.customer.email?.length > 0 || store.delivery.customer.generateMail);
    setCurrentStep(0);
    setABTestNumber();
  }, []);

  const onAddressSearch = useAddressSearch();


  const onAddressSelect = ({ address, placeId }) => {
    dispatch({ type: 'SET_NEW_ADDRESS', payload: { address, placeId }});
  };

  const setABTestNumber = () => {
    dispatch({
      type: 'SET_ABTESTING_NUMBER', payload: {
        abTest: Math.trunc(Math.random() * 100),
      },
    });
  };

  // Récupérer la distance entre le point de départ et le point d'arrivée
  useEffect(() => {
    const fetchDistanceData = async() => {
      await distanceFetch.get(`/addresses/distance?originId=${store.delivery.customer.address.id}&destinationId=${store.delivery.drive.addressId}&tradeId=${store.delivery.drive.tradeId}`);
      if (distanceFetch.response.ok) {
        dispatch({ type: 'SET_ORDER_DISTANCE_INFOS', payload: {
          distance: distanceFetch.response.data.distance,
          isValidatedDistance: distanceFetch.response.data.isValidatedDistance,
        }});
      }
    };

    if (store.delivery.customer.address.id && store.delivery.drive.driveId) {
      fetchDistanceData();
    }
    if (!store.delivery.customer.address.id) {
      dispatch({ type: 'SET_ORDER_DISTANCE_INFOS', payload: {
        distance: 0,
      }});
    }
  }, [store.delivery.customer.address]);

  const resetEmailField = () => {
    form.setFieldsValue({
      email: '',
    });
  };

  const updateAddress = async(userAddress) => {
    setUpdateAddressLoading(true);
    await updateUserAddress({
      userId: store.delivery.customer.userId,
      data: userAddress,
    }).then((response) => {
      setUpdateAddressLoading(false);
      if (response.type === 'success') {
        dispatch({ type: 'SET_CUSTOMER_SELECTED_ADDRESS', payload: { ...response.data, id: response.data.id }});
        setCurrentStep(1);
        setStepsValidated([true, false, false]);
        dispatch({ type: 'SET_NEW_ADDRESS', payload: {}});
        notification.push('success', t('ADDRESS_UPDATED'));
      } else {
        return notification.push('error', t('ERROR_OCCURED_ON_ADDRESS_CREATION'));
      }
    });
  };

  const createUserAddress = async(userAddress) => {
    setUpdateAddressLoading(true);
    await writeAddress({
      address: {
        ...userAddress,
        original_address: userAddress.originalAddress
      },
    }).then((response) => {
      setUpdateAddressLoading(false);
      if (response.type === 'success') {
        dispatch({ type: 'SET_CUSTOMER_SELECTED_ADDRESS', payload: { ...userAddress, id: response.data }});
        setCurrentStep(1);
        setStepsValidated([true, false, false]);
      } else {
        notification.push('error', t('ERROR_OCCURED_ON_ADDRESS_CREATION'));
      }
    });
  };


  const generateUserAddress = (values, deliveryAddress, placeId) => {
    return generateAddressObject({
      addressFormValues: {
        address: values.address,
        elevator: formatElevatorFormDataToApi(values.elevator),
        floor: values.floor,
        comment: values.comment,
      },
      addressInitialValues: {
        address: deliveryAddress.address,
        elevator: formatElevatorFormDataToApi(deliveryAddress.elevator),
        floor: deliveryAddress.floor,
        additionalInfo: deliveryAddress.additional_info,
      },
      addressPlaceId: placeId,
    });
  };

  const handleCreateUser = async(values, userAddress) => {
    const createdCustomerId = await createUser(values);
    if (!createdCustomerId) {
      return null;
    }

    createUserAddress({
      ...userAddress,
      user_id: createdCustomerId,
      zip_code: '',
      city: '',
      country: '',
      address: userAddress.originalAddress,
    });

    return createdCustomerId;
  };

  const onFinish = async(values) => {
    const userAddress = generateUserAddress(
      values,
      deliveryAddress,
      store.delivery.customer.createNewAddress.placeId
    );

    if (!store.delivery.customer.userId) {
      await handleCreateUser(values, userAddress);
    } else {
      if (!store.delivery.customer.address.id) {
        createUserAddress({ ...userAddress, user_id: store.delivery.customer.userId });
      } else if (userAddress) {
        updateAddress(userAddress);
      } else {
        setCurrentStep(1);
        setStepsValidated([true, false, false]);
      }
    }
  };

  /**
   * createUser
   * @param {Object} values
   * @return {number}
   */
  async function createUser(values) {
    await userFetch.post('/users', {
      first_name: values.firstName,
      last_name: values.lastName,
      telephone: values.telephone,
      email: values.email,
      no_email: values.email.length === 0 ? true : false,
    });
    if (userFetch.response.ok) {
      notification.push('success', t('DELIVERY_USER_CREATED'));
      dispatch({ type: 'CREATE_DELIVERY_CUSTOMER', payload: {
        firstName: values.firstName,
        lastName: values.lastName,
        telephone: values.telephone,
        email: values.email,
        userId: userFetch.response.data,
        isExisting: true,
      }});
      form.setFieldsValue({
        isExisting: true,
      });

      return userFetch.response.data;
    } else {
      if (userFetch.response.status > 409) {
        notification.push('error', t('INTERNAL_ERROR'));
      }
      form.validateFields(['email']);
    }
  }

  const prev = () => {
    dispatch({ type: 'RESET_DELIVERY_FIELDS' });
    setShowCustomerForm(false);
    form.resetFields();
  };


  useEffect(() => {
    const address = store.delivery.customer.address;

    if (address) {
      const formattedAddress = addressFormatter({
        address: address.address,
        zip_code: address.zip_code,
        city: address.city,
      });
      const elevator = transformedElevatorData(address.elevator);

      setDeliveryAddress({
        original_address: address.original_address,
        address: formattedAddress,
        elevator: elevator,
        floor: address.floor,
        additional_info: address.additional_info,
      });

      form.setFieldsValue({
        address: formattedAddress,
        elevator: elevator,
        floor: address.floor,
        comment: address.additional_info,
      });
    }
  }, [store.delivery.customer.address]);

  return (
    <>
      <Space
        direction='vertical'
        align='center'
        css={{
          display: showCustomerForm ? 'none' : 'flex',
          width: '100%',
          height: 'calc(100% - 46px)',
          paddingBottom: '20px',
        }}
      >
        <UserSearch inDeliveryCreation={true} formStep1={form} />
        <ButtonCreateNewUser formStep1={form}/>
      </Space>
      <Form
        name="CreateDeliveryForm"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        css={{ display: showCustomerForm ? 'block' : 'none' }}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <PersonalInformationsFieldset
              form={form}
              userFetch={userFetch}
              resetEmailField={resetEmailField}
              previousEmailValue={form.getFieldValue('email')}
            />
          </Col>
          <Col span={24}>
            {deliveryAddress &&
              <RecipientDeliveryAddress
                form={form}
                originalAddress={deliveryAddress.address}
                onAddressSearch={onAddressSearch}
                onAddressSelect={onAddressSelect}
                showOriginalAddress={store.delivery.customer.createNewAddress.address || deliveryAddress.original_address}
              />
            }
          </Col>
        </Row>

        <div css={{ display: 'flex', justifyContent: 'center', padding: '25px 0', gap: 100 }}>
          <Button icon={<ArrowLeftOutlined />} id='button-prev-create-step1' type="primary" htmlType="button" name="prev" onClick={() => prev()}>
            {t('PREVIEWS')}
          </Button>
          <Button id='button-next-create-step1'type="primary" onClick={() => form.submit()} name="next" loading={userFetch.loading || updateAddressLoading }>
            {t('NEXT')}
            <ArrowRightOutlined />
          </Button>
        </div>

      </Form>
    </>
  );
}

Step1.propTypes = {
  store: PropTypes.object,
  dispatch: PropTypes.func,
};

export default Step1;
