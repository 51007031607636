import { calculateInitialTimes, createNewEndDate, createNewStartDate, roundToNextQuarterHour } from '@shopopop/backoffice-frontend-utils';
import { Form, FormInstance } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const useDeliveryDateTime = (form: FormInstance, forceRefresh?: boolean) => {
  const { t } = useTranslation();

  const { start: initialStartDate, end: initialEndDate } = calculateInitialTimes({
    now: dayjs(),
  });

  const [startDate, setStartDate] = useState<dayjs.Dayjs>(form.getFieldValue('startDate') || initialStartDate);
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(form.getFieldValue('endDate') || initialEndDate);

  const [initOnce, setInitOnce] = useState(false);

  const selectedDate = Form.useWatch('deliveryDate', form);
  const startHour = Form.useWatch('startDate', form);
  const endHour = Form.useWatch('endDate', form);


  useEffect(() => {
    if (forceRefresh !== undefined) {
      handleStartTimeChange(startHour);
      handleEndTimeChange(endHour);
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (startDate && !initOnce && selectedDate && selectedDate !== 'other') {
      const newDate = dayjs(selectedDate);
      const roundedStartDate = roundToNextQuarterHour(newDate.hour(startDate.hour()).minute(startDate.minute()));
      form.setFieldsValue({
        deliveryDate: dayjs(startDate).startOf('day').format(),
        _startTime: roundedStartDate,
        _endTime: roundToNextQuarterHour(roundedStartDate.add(2, 'hour')),
      });
      setInitOnce(true);
    }
  }, [selectedDate]);

  const validateTimeRange = async() => {
    if (!startDate || !endDate) {
      return Promise.reject(new Error(t('DELIVERY_TIME_REQUIRED_ERROR')));
    }
    if (startDate.isBefore(dayjs())) {
      return Promise.reject(new Error(t('DELIVERY_TIME_PAST_ERROR')));
    }

    // Vérification que l'heure de début est entre 08h00 et 20h00
    const startOfDay = dayjs(startDate).hour(8).minute(0).second(0);
    const endOfDay = dayjs(startDate).hour(20).minute(0).second(0);
    if (!startDate.isBetween(startOfDay, endOfDay, 'minute', '[]')) {
      return Promise.reject(new Error(t('START_TIME_OUT_OF_RANGE')));
    }

    // Vérification que l'heure de fin est entre 10h00 et 21h00
    const startOfEndRange = dayjs(endDate).hour(9).minute(0).second(0);
    const endOfEndRange = dayjs(endDate).hour(21).minute(0).second(0);
    if (!endDate.isBetween(startOfEndRange, endOfEndRange, 'minute', '[]')) {
      return Promise.reject(new Error(t('WRONG_DELIVERY_HOUR')));
    }

    if (endDate.isSameOrBefore(startDate)) {
      return Promise.reject(new Error(t('END_HOUR_IS_EQUAL_OR_BEFORE_START_HOUR')));
    }
    if (endDate.startOf('minute').diff(startDate.startOf('minute'), 'minute') < 60) {
      return Promise.reject(new Error(t('WRONG_INTERVAL_BETWEEN_DELIVERY_DATES')));
    }

    /**
     * On veux min 1h entre le début et la fin
     * On veux que ce soit à l'heure de maintenant +2H pour le début
     * On ne peux mettre des heures qu'entre 8h et 21h
     *
     * On a 2 variables dayjs à check : startDate et endDate
     *
     * Si cest règle ne sont pas respecté, il faut renvoyer :
     * return Promise.reject(new Error(t('WRONG_DELIVERY_HOUR')));
     */
    const now = dayjs();
    const minStartTime = now.add(2, 'hour');
    const startHour = startDate.hour();
    const endHour = endDate.hour();

    const isStartTimeValid = startDate.isAfter(minStartTime);
    const isEndTimeValid = endDate.isAfter(startDate.add(1, 'hour').subtract(1, 'second'));
    const isStartHourInRange = startHour >= 8 && startHour <= 21;
    const isEndHourInRange = endHour >= 8 && endHour <= 21;

    if (!isStartTimeValid) {
      const newInitialStartDate = roundToNextQuarterHour(dayjs().add(2, 'hour'));
      setStartDate(newInitialStartDate);
      form.setFieldsValue({ startDate: newInitialStartDate });
      return Promise.reject(new Error(t('UPDATE_DELIVERY_TIMESLOT')));
    }

    if (!isEndTimeValid || !isStartHourInRange || !isEndHourInRange) {
      return Promise.reject(new Error('WRONG_DELIVERY_HOUR'));
    }
    return Promise.resolve();
  };

  const handleStartTimeChange = (time: dayjs.Dayjs | null) => {
    if (time) {
      const selectedDateDay = dayjs(selectedDate).startOf('day');
      const newTime = roundToNextQuarterHour(dayjs(selectedDateDay).hour(time.hour()).minute(time.minute()));
      setStartDate(newTime);

      // Adjust the end date if start time is 20:00
      let newEndTime = newTime.add(2, 'hour');
      if (newTime.isSameOrAfter(dayjs().hour(19).minute(0)) && newTime.isBefore(dayjs().hour(20).minute(0))) {
        newEndTime = newTime.add(2, 'hour').minute(0);
      }
      if (newTime.hour() === 20) {
        newEndTime = newTime.add(1, 'hour');
      }

      setEndDate(newEndTime);
      form.setFieldsValue({ startDate: newTime });
      form.setFieldsValue({ endDate: newEndTime });
      form.validateFields(['timeRange']);
    }
  };

  const handleEndTimeChange = (time: dayjs.Dayjs | null) => {
    if (time) {
      const selectedDateDay = dayjs(selectedDate).startOf('day');
      const newTime = roundToNextQuarterHour(dayjs(selectedDateDay).hour(time.hour()).minute(time.minute()));
      setEndDate(newTime);
      form.setFieldsValue({ endDate: newTime });
      form.validateFields(['timeRange']);
    }
  };

  const handleDateChange = (value: string) => {
    const newStartDate = createNewStartDate(value, startDate.hour(), startDate.minute());
    const newEndDate = createNewEndDate(value, endDate.hour(), endDate.minute());
    setStartDate(newStartDate);
    setEndDate(newEndDate);

    const deliveryDateValue = dayjs(value).startOf('day').format();

    form.setFieldsValue({
      startDate: newStartDate,
      endDate: newEndDate,
      deliveryDate: deliveryDateValue,
      _startTime: newStartDate,
      _endTime: newEndDate,
    });

    form.validateFields(['timeRange']);
  };

  return {
    startDate,
    endDate,
    validateTimeRange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleDateChange,
  };
};

export default useDeliveryDateTime;
