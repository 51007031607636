import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { countryAlphaOrder } from '@shopopop/backoffice-frontend-utils';
import { mapI18N, useChangeLanguage, useScreenSize } from '@shopopop/react-hooks';
import type { MenuProps } from 'antd';
import { Avatar, Dropdown, Menu, Space } from 'antd';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type MenuItem = Required<MenuProps>['items'][number];

interface MyAccountProps {
  onLogout: () => void;
  transformPosition?: { x: number; y: number };
}

/**
 * The MyAccount component.
 * @return {ReactNode} The MyAccount component.
 */
function MyAccount({ onLogout, transformPosition = { x: 0, y: 0 }}: MyAccountProps): ReactNode {
  const { t, i18n } = useTranslation();
  const { tabletMode } = useScreenSize();
  const handleChangeLanguage = useChangeLanguage();

  const languages = useMemo(
    () =>
      countryAlphaOrder(mapI18N).map(({ name }) => ({
        key: name,
        label: name,
        onClick: () => handleChangeLanguage(name),
      })),
    [mapI18N],
  );
  const selectedLanguage = useMemo(() => mapI18N.find(({ keys }) => keys.includes(i18n.language))?.name, [i18n.language, mapI18N]);

  const items: MenuItem[] = [
    {
      key: 'languages',
      label: t('LANGUAGE_CHOICE'),
      type: 'group',
      children: languages,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: <div onClick={onLogout}>{t('LOG_OUT')}</div>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Dropdown dropdownRender={() => <StyledMenu items={items} mode="inline" transformPosition={transformPosition} onLogout={onLogout} activeKey={selectedLanguage ?? ''} />}>
      <Space>
        <Avatar size="small" icon={<UserOutlined />} />
        {!tabletMode && t('MY_ACCOUNT_SECTION')}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}

const StyledMenu = styled(Menu)`
  transform: translate(
    ${(props: MyAccountProps) => props.transformPosition!.x}px, 
    ${(props: MyAccountProps) => props.transformPosition!.y}px
  );
`;

export default MyAccount;
