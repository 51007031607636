import { useScreenSize } from '@shopopop/react-hooks';
import { Space, Typography } from 'antd';
import { ReactNode } from 'react';

const { Text } = Typography;

interface DeliveryPickupTupleProps {
  name: string;
  details: string;
}

function DeliveryPickupTuple({ name, details }: DeliveryPickupTupleProps): ReactNode {
  const { tabletMode, mobileMode } = useScreenSize();

  return (
    <Space direction={mobileMode ? 'vertical' : (tabletMode ? 'horizontal' : 'vertical')} size={mobileMode ? 0 : tabletMode ? 'small' : 0}>
      <Text>{name}</Text>
      <Text type="secondary">{details}</Text>
    </Space>
  );
}

export default DeliveryPickupTuple;
