// ReportIndividualDelivererModal.tsx
import { UserDeleteOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../../../hooks/src';
import { renderContent, renderFooter, renderTitle } from './ReportIndividualDelivererModalUtils';

const { useForm } = Form;
// need to be typed like this because of the rtk query dependency
interface modalParamsResponse {
  data?: Array<number> | undefined;
  error?: unknown | undefined;
  isLoading?: boolean | undefined;
  isError?: boolean | undefined;
}

interface EditDeliveryModalProps<T extends modalParamsResponse = modalParamsResponse> {
  onSubmit: (values: string) => void;
  individualDelivererRTKquery: [
    // eslint-disable-next-line @typescript-eslint/ban-types
    banDelivererTrigger: Function,
    response: T,
    _: unknown,
  ];
  idIndividualDeliverer: number | undefined;
  deliveryId: string;
}

const ReportIndividualDelivererModal: React.FC<EditDeliveryModalProps> = ({ onSubmit, individualDelivererRTKquery, idIndividualDeliverer, deliveryId }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [banDelivererTrigger, { data = [], isLoading = true }] = individualDelivererRTKquery;
  const [form] = useForm();
  const { mobileMode } = useScreenSize();
  const { t } = useTranslation();

  const handleOpenModal = () => {
    banDelivererTrigger(idIndividualDeliverer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onSubmit(values['description']);
      handleCloseModal();
    });
  };
  const isReported = useMemo(() => data.some((item) => item === Number(deliveryId)), [data, deliveryId]);
  const Title = useMemo(() => renderTitle(isLoading, isReported, t), [isLoading, isReported, t]);
  const Content = useMemo(() => renderContent(isLoading, isReported, t, form), [isLoading, t, form, isReported]);
  const Footer = useMemo(() => renderFooter(isReported, handleCloseModal, handleSubmit, t), [data, handleCloseModal, handleSubmit, t]);

  return (
    <>
      {mobileMode ? (
        <Button type="text" size="small" onClick={handleOpenModal} icon={<UserDeleteOutlined />} />
      ) : (
        <Button type="text" onClick={handleOpenModal}>
          {t('CTP_REPORTING_TITLE')}
        </Button>
      )}

      <Modal open={isModalOpen} onCancel={handleCloseModal} footer={Footer} title={Title}>
        {Content}
      </Modal>
    </>
  );
};

export default ReportIndividualDelivererModal;
