type ElevatorStatus = 'LIFT_YES' | 'LIFT_NO' | 'LIFT_UNKNOW';

const transformElevatorForHuman = (
  elevator: boolean | null | undefined,
): ElevatorStatus => {
  if (elevator === true) return 'LIFT_YES';
  if (elevator === false) return 'LIFT_NO';
  return 'LIFT_UNKNOW';
};

export default transformElevatorForHuman;
