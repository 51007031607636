import { theme } from 'antd';
import { useEffect, useState } from 'react';

interface ScreenSize {
  width: number;
  height: number;
  orientation: 'portrait' | 'landscape';
  mobileMode: boolean;
  tabletMode: boolean;
}

/**
 * useScreenSize
 * @example const { width, height, orientation, mobileMode } = useScreenSize();
 * @return {ScreenSize}
 */
function useScreenSize(): ScreenSize {
  const { token } = theme.useToken();

  const getScreenSize = (): ScreenSize => ({
    width: window.innerWidth,
    height: window.innerHeight,
    orientation: window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape',
    mobileMode: window.innerWidth <= token.screenSM,
    tabletMode: window.innerWidth <= token.screenLG,
  });

  const [screenSize, setScreenSize] = useState(getScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return screenSize;
}

export default useScreenSize;
